import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    phoneNumber: null,
    payrollNumber: null,
    nationalIdNumber: null,
    quoteDetails: null,
    advanceDetails: null,
    resetPin: null,
    securityQuestion: null,
    jilipePercentage: null,
    customer: null,
    otp: null,
    allowOptIn: false,
    supportContact: null
  },
  getters: {
    getToken: state => state.token,
    getPhoneNumber: state => state.phoneNumber,
    getPayrollNumber: state => state.payrollNumber,
    getNationalIdNumber: state => state.nationalIdNumber,
    getQuoteDetails: state => state.quoteDetails,
    getAdvanceDetails: state => state.advanceDetails,
    getResetPin: state => state.resetPin,
    getSecurityQuestion: state => state.securityQuestion,
    getJilipePercentage: state => state.jilipePercentage,
    getCustomer: state => state.customer,
    getOtp: state => state.otp,
    getAllowOptIn: state => state.allowOptIn,
    getSupportContact: state => state.supportContact
  },
  mutations: {
    setToken(state, value) {
      state.token = value
    },

    setPhoneNumber(state, value) {
      state.phoneNumber = value
    },

    setPayrollNumber(state, value) {
      state.payrollNumber = value
    },

    setNationalIdNumber(state, value) {
      state.nationalIdNumber = value
    },

    setQuoteDetails(state, value) {
      state.quoteDetails = value
    },

    setAdvanceDetails(state, value) {
      state.advanceDetails = value
    },

    setResetPin(state, value) {
      state.resetPin = value
    },

    setSecurityQuestion(state, value) {
      state.securityQuestion = value
    },

    setJilipePercentage(state, value) {
      state.jilipePercentage = value
    },

    setCustomer(state, value) {
      state.customer = value
    },
    
    setOtp(state, value) {
      state.otp = value
    },

    setAllowOptIn(state, value) {
      state.allowOptIn = value
    },

    setSupportContact(state, value) {
      state.supportContact = value
    }
  },
  actions: {
  },
  modules: {
  }
})
