<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav :path="back"></TopNav>

            <div class="custom-mt-12">
                <h5 class="text-muted text-center fw-bold">Reset your Jilipe Sasa PIN</h5>
            </div>

            <div class="custom-mt-12">
                <div class="px-4">
                    <b-alert class="text-center" variant="success" show>
                        OTP sent to the phone number {{ phoneNumber }} successfully.
                    </b-alert>
                </div>

                <div>
                    <p class="text-muted text-center mb-0 mt-4">OTP</p>
                    <div class="d-flex justify-content-center">
                        <b-form-input placeholder="Enter OTP" class="custom-input-border-bottom" type="number"
                            v-model="otp"></b-form-input>
                    </div>

                    <p class="text-muted text-center mt-4 mb-2">Did not receive OTP?</p>

                    <div class="d-flex justify-content-center">                        
                        <b-button size="sm" class="custom-button-orange btn-sm" @click="submitPinReset()" :disabled="btnDisabled">
                            <span> {{ btnText}} </span>                                                
                        </b-button>
                    </div>
                    
                </div>

                <div class="custom-mt-12 d-flex justify-content-center">
                    <b-button class="custom-orange-button" @click="verifyOTP()" :disabled="submitBtn">Verify OTP</b-button>
                </div>

                <div class="custom-mt-8 d-flex justify-content-center">
                    <img src="@/assets/img/logo.png" width="150px" />
                </div>

                <div class="py-4 d-flex justify-content-center">
                    <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
    name: 'LoginPin',
    components: {
        TopNav,
        BottomMenu
    },

    data() {
        return {
            count: 39,
            btnText: "Resend OTP in 40",
            btnDisabled: true,
            back: "/login/step-one",
            submitBtn: false,
            otp: null,
            phoneNumber: null,

        }
    },

    computed: {
        ...mapGetters(["getPhoneNumber", "getPayrollNumber", "getNationalIdNumber"])
    },

    mounted() {
        if (this.getPhoneNumber == null || this.getPayrollNumber == null || this.getNationalIdNumber == null) {
            this.navigate("/login/step-one")
            this.showError("Details could not be found!")
        }
        else {
            this.otpCounter()
            this.phoneNumber = this.getPhoneNumber.slice(0, -3) + "***";
        }        
    },

    methods: {
        ...mapMutations([
            "setOtp"
        ]),

        otpCounter() {
            this.count = 40

            const countdown = setInterval(() => {
                this.btnText = "Resend OTP in " + this.count
                this.count--        

                if (this.count === 0) {
                    this.btnDisabled = false
                    this.btnText = "Resend OTP"
                    clearInterval(countdown);
                }
            }, 1000); 
        },

        submitPinReset() {
            if(this.getPhoneNumber != null && this.getPayrollNumber != null && this.getNationalIdNumber != null) {

            let that = this
            that.btnDisabled = true

            let data = {}

            data.phoneNumber = this.getPhoneNumber
            data.payrollNumber = this.getPayrollNumber
            data.nationalIdNumber = this.getNationalIdNumber

            api.resetPin(data)
                .then((response) => {
                    that.showSuccess("OPT sent successfully")
                    that.otpCounter()
                })
                .catch((error) => {
                    that.showError(error)
                })
            }
            else {
            this.showError("Details cannot be empty")
            }        
        },

        verifyOTP() {
            let that  = this

            if(this.otp != null) {
                that.setOtp(this.otp)
                that.navigate("/set-pin")
            }
            else {
                that.showError("OTP cannot be empty!.")
            }            
        },

        fetchQuestions() {
            let that = this

            let data = {}

            data.phoneNumber = this.getPhoneNumber
            data.payrollNumber = this.getPayrollNumber
            data.nationalIdNumber = this.getNationalIdNumber

            api.myQuestions(data)
                .then((response) => {
                    that.questions = response.data
                })
                .catch((error) => {
                    that.showError(error)
                })
        },
    }
}
</script>
    