<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav :path="back" :signOutIcon="true"></TopNav>

            <div class="px-4">
                <div class="d-flex flex-column justify-content-center mt-4">
                    <h5 class="custom-text-orange text-center fw-bold">Opt Out</h5>
                    
                    <b-alert class="text-center mt-2" variant="warning" show>
                        <p class="mb-2">You are about to <span class="fw-bold">opt out</span> to your Jilipe Sasa account.</p>
                        <p class="mb-2">While you're opted out, you won't be able to earn, and you'll need to opt back in to access your account again.</p>
                    </b-alert>

                    <b-button class="custom-blue-button" :disabled="submitBtn" @click="submit()">
                        Proceed With Opt Out
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    import api from '@/apis/api'
    import TopNav from '@/components/TopNav.vue'
    import BottomMenu from '@/components/BottomMenu.vue'

    export default{
        name: 'OptOut',
        components: {
            TopNav,
            BottomMenu
        },
        data() {
            return {
                back: "/home",
                submitBtn: false
            }
        },

        methods: {
            submit() {
                let that = this
                this.submitBtn = true

                api.optOut()
                    .then((response) => {
                        that.submitBtn = false
                        
                        if(response.data) {
                            that.showSuccess("Opt Out Successfull")
                            that.navigate("/login/step-one")
                        }
                        else {
                            that.showError("There was an issue with the opt out request. Please try again later.")
                        }                        
                    })
                    .catch((error) => {
                        that.showError(error)
                        that.submitBtn = false
                    })
            }
        }
    }
</script>