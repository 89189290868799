<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav></TopNav>

            <div class="px-4">
                <template v-if="!getAllowOptIn">
                    <div class="d-flex flex-column justify-content-center mt-4">
                        <h5 class="custom-text-orange text-center fw-bold">Opt In to Jilipe Sasa</h5>

                        <b-alert class="text-center mt-2" variant="warning" show>
                            <p class="mb-2">You are currently opted out of your Jilipe Sasa account.</p>
                            <p class="mb-2">
                                To opt back in, contact customer support via : 
                                <template v-if="getSupportContact != null">
                                    <span class="fw-bold">{{ getSupportContact }}</span>
                                </template>                                
                            </p>
                        </b-alert>

                        <b-button class="custom-blue-button" :disabled="submitBtn" @click="goHome()">
                            Go Back Home
                        </b-button>
                    </div>
                </template>

                <template v-else>
                    <div class="custom-mt-12">
                        <p class="text-muted text-center">
                            Welcome to <span class="fw-bold">Jilipe Sasa.</span>
                            Before you can login to your account, please opt in.
                        </p>
                    </div>

                    <div>
                        <p class="text-muted text-center mb-0 mt-4">Phone Number</p>
                        <b-form-input class="custom-input-border-bottom" type="text" v-model="getPhoneNumber"
                            readonly></b-form-input>
                    </div>

                    <div>
                        <p class="text-muted text-center mb-0 mt-4">National ID Number</p>
                        <b-form-input class="custom-input-border-bottom" type="text" placeholder="Enter ID Number"
                            v-model="nationalId"></b-form-input>
                    </div>

                    <div>
                        <p class="text-muted text-center mb-0 mt-4">Payroll Number</p>
                        <b-form-input class="custom-input-border-bottom" type="text" placeholder="Enter Payroll Number"
                            v-model="payrollNumber"></b-form-input>
                    </div>

                    <div class="custom-mt-12 d-flex justify-content-center">
                        <b-button class="custom-orange-button" @click="submit()" :disabled="submitBtn">Opt In</b-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
    name: 'OptIn',
    components: {
        TopNav,
        BottomMenu
    },
    data() {
        return {
            back: "/login/step-one",
            nationalId: null,
            payrollNumber: null,
            submitBtn: false
        }
    },

    computed: {
        ...mapGetters(["getPhoneNumber", "getAllowOptIn", "getSupportContact"])
    },

    mounted() {
        if (this.getPhoneNumber == null) {
            this.navigate("/login/step-one")
            this.showError("Phone number not found")
        }
    },

    methods: {
        ...mapMutations([
            "setPayrollNumber",
            "setNationalIdNumber",
        ]),

        submit() {
            if (this.payrollNumber != null && this.nationalId != null) {
                let that = this

                let data = {}
                data.nationalIdNumber = this.nationalId
                data.phoneNumber = this.getPhoneNumber
                data.optIn = true

                this.submitBtn = true

                api.optIn(data)
                    .then((response) => {
                        that.setPayrollNumber(that.payrollNumber)
                        that.setNationalIdNumber(that.nationalId)

                        that.navigate("/login/step-two")
                        that.submitBtn = false
                    })
                    .catch((error) => {
                        that.showError(error)
                        that.submitBtn = false
                    })
            }
        },

        goHome() {
            this.navigate("/login/step-one")
        }
    }
}
</script>