<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav></TopNav>
  
        <div class="custom-mt-12">
          <h5 class="text-muted text-center fw-bold">Welcome to Jilipe Sasa</h5>
        </div>
  
        <div class="custom-mt-12">
          <div class="mt-4">
            <p class="text-muted text-center">Enter your mobile number to login</p>
          </div>     
  
          <div class="d-flex justify-content-center px-4">
            <div class="d-flex px-4">
              <b-form-input placeholder="Enter phone number" class="custom-input-border-bottom-alt" value="254" readonly></b-form-input>
              <b-form-input placeholder="Enter phone number" class="custom-input-border-bottom" type="number" @keyup.enter="submit()" v-model="phoneNumber"></b-form-input>
            </div>            
          </div>     

          <p class="text-danger text-center custom-small-text mt-2" v-if="phoneError">Phone number format should be 254712345678</p>
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <b-button class="custom-orange-button" @click="submit()" :disabled="submitBtn">Continue</b-button>
          </div>

          <div class="px-4 mt-4" v-if="allowRegistration">
              <b-alert class="text-center" variant="danger" show>Phone number could not be found. 
                <span class="fw-bold text-decoration-underline custom-pointer" @click="navigate('/recommend')">Register here </span> 
                if you would like to recommend your employer to join Jilipe Sasa.</b-alert>
          </div>
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <img src="@/assets/img/logo.png"  width="150px"/>
          </div>
  
          <div class="mt-4 d-flex justify-content-center">
            <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
          </div>    
          
        </div>
      </div>
    </div>  
  </template>
  
  <script>
    import api from "@/apis/api"
    import { mapMutations } from 'vuex'
    import TopNav from '@/components/TopNav.vue'
    import BottomMenu from '@/components/BottomMenu.vue'
    
    export default {
      name: 'LoginPhone',
      components: {
        TopNav,
        BottomMenu
      },
    
      data() {
        return {
          back: "/login/step-two",
          stage: "Auth",
          phoneNumber: null,
          phoneError: false,
          submitBtn: false,
          allowRegistration: false,
        }
      },

      created() {
        localStorage.clear()
      },

      methods: {
        ...mapMutations([
          "setPhoneNumber",
          "setAllowOptIn",
          "setSupportContact"
        ]),

        submit() {
          if(this.phoneNumber != null) {
            if (this.phoneNumber.length !== 9) {
              this.showError("Incorrect phone number format!")
              this.phoneError = true
            }
            else {
              let that = this
              this.submitBtn = true

              let phone = "254" + this.phoneNumber

              api.verifyPhone(phone)
                .then((response) => {
                  that.setPhoneNumber(phone)
                  that.submitBtn = false

                  if(response.data.status == 'OPT_IN_COMPLETED') {                    
                    that.navigate('/login/step-two')
                  }
                  else if(response.data.status == 'OPT_IN_PENDING') {                    
                    that.setAllowOptIn(true)
                    that.navigate('/login/opt-in')
                  }        
                  else if(response.data.status == 'OPTED_OUT') {
                    that.setSupportContact(response.data.supportContact)
                    that.navigate('/login/opt-in')
                  }       
                })
                .catch((error) => {
                  that.submitBtn = false
                  let errorCode = error.response.status

                  if(errorCode == 404) {
                    that.showError("Phone number could not be found")
                    that.allowRegistration = true
                  }
                  else {
                    that.showError(error)
                  }             
                })          
            }
          }
          else {
            this.showError("Phone number cannot be empty")
          }        
        }
      }
    }
  </script>
  