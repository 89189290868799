<template>
    <div class="custom-mobile-view">
        <div class="custom-container bg-white">
            <TopNav :page="stage" :signOutIcon="true"></TopNav>

            <div class="p-4 custom-mb-20 custom-scroll-container ">
                <div class="d-flex justify-content-center">
                    <img src="@/assets/img/logo.png" width="100px" />
                </div>

                <div class="mt-4">
                    <h4 class="text-center mb-4">52-Week Money Challenge</h4>

                    <p>
                        Using the 52-week money challenge, you should deposit an increasing amount of money into your
                        savings each week for one year. Match each week’s savings amount with the number of the week in your
                        challenge. In other words, you’ll save Ksh 1 the first week, Ksh 2 the second week, Ksh 3 the third
                        week, and so on until you put away Ksh 52 in week 52. Those numbers are guidelines, but you can get
                        creative with the amount you save each week if you’d like to save more over the year or you don’t
                        have enough extra cash available for the regular challenge. If you stick to this challenge
                        throughout the entire year, you’ll save a total of Ksh 1,378.
                    </p>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Week number</th>
                                <th>Weekly deposit</th>
                                <th>Total savings</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(week, index) in weeks" :key="index">
                                <td>{{ week.weekNumber }}</td>
                                <td>{{ week.weeklyDeposit }}</td>
                                <td>{{ week.totalSavings }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 class="mt-4">Benefits of the 52-week savings challenge</h4>
                    <ul>
                        <li>You start with a small, manageable amount, making it less intimidating for beginners.</li>
                        <li>Helps you identify your spending habits as you find ways to save each week.</li>
                        <li>You can start it at any time of the year, although aligning it with the New Year can make it a
                            productive resolution.</li>
                        <li>Serves as a stepping stone toward achieving larger financial goals, like homeownership or any
                            other desire.</li>
                        <li>Provides a systematic and fun way to save money.</li>
                    </ul>

                    <h4 class="mt-4">How to get started</h4>
                    <p>
                        It’s important to first find somewhere to store your savings that’s manageable, risk-free and has
                        growth potential. A simple piggy bank may work for you, but not everyone has cash on hand to deposit
                        each week. Plus, having your savings in such an accessible place may tempt you to dip into it before
                        the end of the challenge.
                    </p>
                </div>


            </div>
        </div>

        <BottomMenu :menu="stage"></BottomMenu>
    </div>
</div></template>
    
<script>
import api from "@/apis/api"
import { mapGetters, mapMutations } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
    name: 'Terms',
    components: {
        TopNav,
        BottomMenu
    },

    data() {
        return {
            stage: "Learn",
            back: "/home",
            weeks: []
        }
    },

    mounted() {
        this.generateWeeksData();
    },

    methods: {
        generateWeeksData() {
            for (let i = 1; i <= 52; i++) {
                this.weeks.push({
                    weekNumber: i,
                    weeklyDeposit: i,
                    totalSavings: this.calculateTotalSavings(i)
                });
            }
        },

        calculateTotalSavings(week) {
            // Calculate total savings based on the given week
            // You can implement your logic here
            return week * (week + 1) / 2; // Example logic (1 + 2 + ... + week)
        }

    }
}
</script>
    