<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav :path="back"></TopNav>
  
        <div class="px-4">
          <div class="custom-mt-12">
            <h5 class="custom-text-orange text-center fw-bold">Reset your Jilipe Sasa PIN</h5>
            <p class="text-muted text-center">
                Welcome to <span class="fw-bold">Jilipe Sasa.</span>
                Please provide us with the following information to reset your PIN.
            </p>
          </div>
        </div>        
  
        <div class="custom-mt-12">
            <div>
                <div class="mt-4">
                    <p class="text-muted text-center">Payroll Number</p>
                </div>     
        
                <div class="d-flex justify-content-center">
                    <b-form-input placeholder="Enter payroll pumber" class="custom-input-border-bottom" type="text" v-model="payrollNumber"></b-form-input>
                </div>  
            </div>      

            <div>
                <div class="mt-4">
                    <p class="text-muted text-center">National ID Number</p>
                </div>     
        
                <div class="d-flex justify-content-center">
                    <b-form-input placeholder="Enter national ID number" class="custom-input-border-bottom" type="number" v-model="nationalIdNumber"></b-form-input>
                </div>  
            </div>     
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <b-button class="custom-orange-button" @click="submitPinReset()" :disabled="submitBtn">Continue</b-button>
          </div>
  
          <div class="custom-mt-12 d-flex justify-content-center">
            <img src="@/assets/img/logo.png"  width="150px"/>
          </div>
  
          <div class="py-4 d-flex justify-content-center">
            <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
          </div>    
          
        </div>
      </div>
    </div>  
  </template>
  
  <script>
  import { mapMutations, mapGetters } from 'vuex'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  import api from '@/apis/api'
  
  export default {
    name: 'ResetPin',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        back: "/login/step-two",
        submitBtn: false,
        phoneNumber: null,
        payrollNumber: null,
        nationalIdNumber: null
      }
    },

    computed: {
        ...mapGetters(["getPhoneNumber"])
    },

    methods: {
      ...mapMutations([
        "setPayrollNumber",
        "setNationalIdNumber",
      ]),

      submitPinReset() {
        if(this.getPhoneNumber != null && this.payrollNumber != null && this.nationalIdNumber != null) {

          let that = this
          that.submitBtn = true

          let data = {}

          data.phoneNumber = this.getPhoneNumber
          data.payrollNumber = this.payrollNumber
          data.nationalIdNumber = this.nationalIdNumber

          api.resetPin(data)
            .then((response) => {
              that.setPayrollNumber(that.payrollNumber)
              that.setNationalIdNumber(that.nationalIdNumber)

              that.navigate("/otp-verification")
              that.showSuccess("OPT sent successfully")
              that.submitBtn = false
            })
            .catch((error) => {
              that.showError(error)
              that.submitBtn = false
            })
        }
        else {
          this.showError("Details cannot be empty")
        }        
      }
    }
  }
  </script>
  