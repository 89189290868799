<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white">
      <TopNav :path="back" :signOutIcon="true"></TopNav>

      <div class="p-4 custom-mb-20 custom-scroll-container ">
        <div>
          <p class="custom-text-blue text-center fw-bold">Kindly review your Jilipe Sasa service request below.</p>
        </div>

        <div class="custom-blue-card mt-4">
          <div class="p-2">
            <p class="text-center text-muted mb-2">Amount requested</p>
            <h4 class="text-center">KES {{ quoteDetails.drawDown.toLocaleString() }}</h4>
          </div>

          <div class="custom-gray-card">
            <div class="py-2 px-4">
              <div class="d-flex justify-content-between">
                <small class="text-white custom-width">{{ quoteDetails.processionFeeDisplayName }}</small>
                <small class="text-white">KES: {{ quoteDetails.processingFee.toLocaleString() }}</small>
              </div>
              <hr />

              <div class="d-flex justify-content-between">
                <small class="text-white custom-width">{{ quoteDetails.accessFeeDisplayName }}</small>
                <small class="text-white">KES: {{ quoteDetails.accessFee.toLocaleString() }}</small>
              </div>
            </div>
          </div>


          <div class="my-2">
            <p class="text-center text-muted mb-2">You will receive</p>
            <h4 class="text-center">KES {{ quoteDetails.total.toLocaleString() }}</h4>
          </div>
        </div>

        <b-button class="custom-block-button custom-blue-button custom-half-radius-button" @click="showPinModal()" block :disabled="submitBtn">Confirm</b-button>

      </div>

      <b-modal modal-class="custom-modal" hide-footer centered v-model="pinModal">
        <template #modal-title>
          Enter PIN
        </template>

        <div class="mt-4">
          <p class="text-muted text-center">Enter your Jilipe Sasa PIN</p>

          <div class="d-flex justify-content-center custom-pin-section">
            <b-row>
              <b-col>
                <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin"
                  v-model="pin[0]" @input="handleInput(0)" ref="input0" :no-controls="true"></b-form-input>
              </b-col>

              <b-col>
                <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin"
                  v-model="pin[1]" @input="handleInput(1)" ref="input1"></b-form-input>
              </b-col>

              <b-col>
                <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin"
                  v-model="pin[2]" @input="handleInput(2)" ref="input2"></b-form-input>
              </b-col>

              <b-col>
                <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin"
                  v-model="pin[3]" @input="handleInput(3)" ref="input3" @keyup.enter="verifyPIN()"></b-form-input>
              </b-col>
            </b-row>
          </div>

          <b-button class="custom-block-button custom-blue-button mt-4" @click="verifyPin()" block :disabled="submitBtn">Confirm</b-button>

          <p class="custom-text-blue text-center custom-small-text mt-2">
            Please enter your Jilipe Sasa PIN in-order to submit your loan request.
          </p>
        </div>


      </b-modal>

      <BottomMenu :menu="stage"></BottomMenu>
    </div>
  </div>
</template>
  
<script>
import api from "@/apis/api"
import { mapGetters, mapMutations } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
  name: 'RequestConfirmationView',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      stage: "Home",
      back: "/home",
      quoteDetails: null,
      submitBtn: false,
      pin: ['', '', '', ''],
      pinModal: false
    }
  },

  computed: {
    ...mapGetters(["getQuoteDetails", "getPhoneNumber"])
  },

  created() {
    if (this.getQuoteDetails != null) {
      this.quoteDetails = this.getQuoteDetails
    }
    else {
      this.navigate("/home")
    }
  },

  methods: {
    ...mapMutations(["setAdvanceDetails"]),

    handleInput(index) {
      if (this.pin[index] === '') {
        this.focusPreviousInput(index)
      } 
      
      else {
        this.focusNextInput(index)
      }
    },

    focusPreviousInput(index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`].focus();
      }
    },

    focusNextInput(index) {
      if (this.pin[index] && index < this.pin.length - 1) {
        this.$refs[`input${index + 1}`].focus();
      }
    },

    formatPin(e) {
      return String(e).substring(0, 1);
    },

    showPinModal() {
      this.pinModal = true
    },

    verifyPin() {
      let that = this

      if (this.pin.some(item => item === '')) {
        this.showError("PIN not complete")
      }
      else {
        this.submitBtn = true

        const pinString = this.pin.join('')
        const data = new URLSearchParams()

        data.append('grant_type', 'password')
        data.append('username', this.getPhoneNumber)
        data.append('password', pinString)

        api.signin(data)
          .then((response) => {
            if (response != undefined) {
              that.confirmTransaction()
            }
            else {
              that.submitBtn = false
              that.showError("An error occured during authentication.")
            }
          })
          .catch((error) => {
            if (error.response) {
              let errorCode = error.response.status
              that.submitBtn = false

              if (errorCode == 401) {
                that.showError("The credentials you provided are incorrect. Please try again")
              }
              else {
                that.showError("An error occured during authentication.")
              }
            }
          })
      }
    },

    confirmTransaction() {
      let that = this

      let data = {}
      data.reference = this.quoteDetails.reference

      this.submitBtn = true

      api.advance(data)
        .then((response) => {
          that.submitBtn = false
          that.setAdvanceDetails(response.data)

          that.navigate("/request-successful")
        })
        .catch((error) => {
          that.submitBtn = false
          that.showError(error)
        })
    }
  }
}
</script>
  