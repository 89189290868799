<template>
    <div class="custom-top-nav">
        <div class="d-flex justify-content-between px-2">
            <div>
                <template v-if="path != null">
                    <font-awesome-icon class="custom-red-icon text-white" :icon="['fa', 'arrow-left']" @click="navigate(path)"/>   
                </template>
                <template v-else-if="page != null">
                    <label class="text-white">{{ page }}</label>
                </template>
            </div>

            <div>
                <template v-if="signOutIcon != null">
                    <b-dropdown class="custom-dropdown-button" v-model="dropdownOptions" no-caret>
                        <template v-slot:button-content>
                            <font-awesome-icon class="custom-red-icon text-white" :icon="['fa', 'ellipsis-vertical']" @click="showDropdownOptions" />
                        </template>
                        <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="optOut()">Opt Out</b-dropdown-item>
                    </b-dropdown>
                    <!-- <font-awesome-icon class="custom-red-icon text-white" :icon="['fas', 'arrow-right-from-bracket']" @click="logout()"/> -->
                </template>
            </div>                      
        </div>       
    </div>
</template>

<script>
export default {
    name: 'TopNav',

    props: {
        page: null,
        path: null,
        signOutIcon: null,
        dropdownOptions: false
    },

    data() {
        return {
            //
        }
    },

    mounted() {
        //
    },

    methods: {
        showDropdownOptions() {
            this.dropdownOptions = !this.dropdownOptions
        },

        logout() {
            this.navigate("/login/step-one")
            this.showSuccess("Succesfully logged out!")
        },

        optOut() {
            this.navigate("/opt-out")
        }
    }
}
</script>