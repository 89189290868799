import Vue from 'vue'
import VueRouter from 'vue-router'

import Splash from '../views/SplashView'

import LoginPhone from '../views/Auth/LoginPhoneView'
import LoginPin from '../views/Auth/LoginPinView'
import SecurityQuestion from '../views/Auth/SecurityQuestionView'
import OptIn from '../views/Auth/OptIn.vue'
import OptOut from '../views/Auth/OptOut.vue'

import ResetPin from '../views/Auth/ResetPin/ResetPinView'
import SetPin from '../views/Auth/ResetPin/SetPinView'

import Home from '../views/Home/HomeView'
import RequestConfirmation from '../views/Home/RequestConfirmationView'
import RequestSuccess from '../views/Home/RequestSuccessView'

import CreateLead from '@/views/Auth/CreateLeadView'

import Statement from '@/views/Statement/StatementView'

import Profile from '@/views/Profile/ProfileView'

import Terms from '@/views/TermsView'

import Learn from '@/views/Learn/LearnView'

import OTP from '@/views/Auth/ResetPin/OTPVerificationView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/otp-verification',
    name: 'otp-verification',
    component: OTP
  },
  {
    path: '/learn',
    name: 'learn',
    component: Learn
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: Terms
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/statement',
    name: 'statement',
    component: Statement
  },
  {
    path: '/security-question',
    name: 'security-question',
    component: SecurityQuestion
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: CreateLead
  },
  {
    path: '/reset-pin',
    name: 'reset-pin',
    component: ResetPin
  },
  {
    path: '/set-pin',
    name: 'set-pin',
    component: SetPin
  },
  {
    path: '/',
    name: 'splash',
    component: Splash
  },

  {
    path: '/login/step-one',
    name: 'step-one',
    component: LoginPhone
  },
  {
    path: '/login/step-two',
    name: 'step-two',
    component: LoginPin
  },
  {
    path: '/login/opt-in',
    name: 'opt-in',
    component: OptIn
  },
  {
    path: '/opt-out',
    name: 'opt-out',
    component: OptOut
  },

  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/request-confirmation',
    name: 'request-confirmation',
    component: RequestConfirmation
  },
  {
    path: '/request-successful',
    name: 'request-successful',
    component: RequestSuccess
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
