<template>
    <div class="custom-mobile-view">
      <div class="custom-container bg-white">
        <TopNav :path="back" :signOutIcon="true"></TopNav>
  
        <div class="p-4 custom-mb-20 custom-scroll-container ">
          <div>
            <p class="custom-text-blue text-center fw-bold">Terms and conditions.</p>
          </div>          
        </div>
  
        <BottomMenu :menu="stage"></BottomMenu>
      </div>
    </div>
  </template>
    
  <script>
  import api from "@/apis/api"
  import { mapGetters, mapMutations } from 'vuex'
  import TopNav from '@/components/TopNav.vue'
  import BottomMenu from '@/components/BottomMenu.vue'
  
  export default {
    name: 'Terms',
    components: {
      TopNav,
      BottomMenu
    },
  
    data() {
      return {
        stage: "Home",
        back: "/home",
      }
    },
  
    computed: {
        
    },
  
    created() {
        
    },
  
    methods: {
        
    }
  }
  </script>
    