<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white">
      <TopNav :page="stage" :signOutIcon="true"></TopNav>

      <div class="p-4 custom-mb-20 custom-scroll-container">
        <template v-if="statement === null">
          <div class="d-flex justify-content-center p-4">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>

        <template v-else>
          <div>
            <div>
              <div class="d-flex custom-pointer" v-if="!filterStatement" @click="toggleFilter()">
                <font-awesome-icon class="custom-text-blue custom-underline p-1" icon="fa-filter" size="sm" />
                <p class="custom-text-blue custom-underline">Show Filter</p>
              </div>

              <div class="d-flex custom-pointer" v-else @click="toggleFilter()">
                <font-awesome-icon class="custom-text-orange custom-underline p-1" icon="fa-filter-circle-xmark"
                  size="sm" />
                <p class="custom-text-orange custom-underline">Hide Filter</p>
              </div>

              <b-card class="mb-4" header="Filter records" v-show="filterStatement">
                <div class="d-flex justify-content-between">
                  <b-button class="custom-blue-button" size="sm" @click="filterThisMonth()">Filter This Month</b-button>
                  <b-button class="custom-orange-button-alt" size="sm" @click="removeFilter()">Remove Filter</b-button>
                </div>
                <hr />

                <b-form-group class="mt-2" label="Date From">
                  <b-form-input type="date" v-model="dateFrom"></b-form-input>
                </b-form-group>

                <b-form-group class="mt-2" label="Date To">
                  <b-form-input type="date" v-model="dateTo"></b-form-input>
                </b-form-group>

                <b-button class="custom-block-button custom-blue-button mt-4" size="sm" @click="getCustomerTransactions()"
                  :disabled="btnSubmit">Filter</b-button>
              </b-card>
            </div>
            <div v-if="statement.length">
              <b-row class="custom-border-bottom-gray py-2" v-for="(transaction, index) in statement">
                <div class="d-flex justify-content-between">
                  <div class="custom-stack">
                    <label class="text-dark fw-bold">{{ transaction.details }}</label>
                    <small class="text-muted">{{ transaction.transactionDate }}</small>
                  </div>

                  <div>
                    <label class="text-muted" v-if="transaction.issue != null">KES {{ transaction.issue.toLocaleString()
                    }}</label>
                    <label class="text-muted" v-else>KES {{ transaction.redeem.toLocaleString() }}</label>
                  </div>
                </div>
              </b-row>
            </div>
            <div v-else>
              <b-alert variant="warning" show>No previous transactions.</b-alert>
            </div>
          </div>
        </template>
      </div>

      <BottomMenu :menu="stage"></BottomMenu>
    </div>
  </div>
</template>
  
<script>
import { mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
  name: 'HomeView',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      stage: "Statements",
      statement: null,
      filterStatement: false,
      dateFrom: null,
      dateTo: null
    }
  },

  created() {
    this.getCustomerTransactions()
  },

  methods: {
    getCustomerTransactions() {
      let that = this

      let url = "/v1/transactions/summary?"

      if (this.dateFrom) {
        url += "dateFrom=" + this.dateFrom + "&";
      }
      if (this.dateTo) {
        url += "dateTo=" + this.dateTo;
      }

      this.statement = null
      this.btnSubmit = true

      api.myTransactions(url)
        .then((response) => {
          that.statement = response.data
          that.btnSubmit = false
          that.filterStatement = false
        })
        .catch((error) => {
          that.showError(error)
          that.btnSubmit = false
        })
    },

    toggleFilter() {
      this.filterStatement = !this.filterStatement
    },

    filterThisMonth() {
      let currentDate = new Date();

      let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

      let year = firstDay.getFullYear()
      let month = String(firstDay.getMonth() + 1).padStart(2, '0')
      let fDay = String(firstDay.getDate()).padStart(2, '0')
      let lDay = String(lastDay.getDate()).padStart(2, '0')

      this.dateFrom = `${year}-${month}-${fDay}`
      this.dateTo = `${year}-${month}-${lDay}`
    },

    removeFilter() {
      this.dateFrom = null
      this.dateTo = null
      this.getCustomerTransactions()
    },
  }
}
</script>
  