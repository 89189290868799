<template>
  <div class="custom-mobile-view">
    <div class="custom-container bg-white">
      <TopNav :path="back"></TopNav>

      <div class="custom-mt-12">
        <h5 class="text-muted text-center fw-bold">Welcome to Jilipe Sasa</h5>
      </div>

      <div class="custom-mt-12">
        <div class="mt-4">
          <p class="text-muted text-center">Enter your Jilipe Sasa PIN</p>
        </div>

        <div class="d-flex justify-content-center custom-pin-section">
          <b-row>
            <b-col>
              <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin" v-model="pin[0]"
                @input="handleInput(0)" ref="input0" :no-controls="true"></b-form-input>
            </b-col>

            <b-col>
              <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin" v-model="pin[1]"
                @input="handleInput(1)" ref="input1"></b-form-input>
            </b-col>

            <b-col>
              <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin" v-model="pin[2]"
                @input="handleInput(2)" ref="input2"></b-form-input>
            </b-col>

            <b-col>
              <b-form-input class="custom-input-border-bottom px-2" type="password" :formatter="formatPin" v-model="pin[3]"
                @input="handleInput(3)" ref="input3" @keyup.enter="submit()"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="custom-mt-12 d-flex justify-content-center">
          <b-button class="custom-orange-button" @click="submit()" :disabled="submitBtn">Login</b-button>
        </div>

        <div class="mt-2 d-flex justify-content-center">
          <small class="text-muted text-decoration-underline custom-pointer" @click="navigate('/reset-pin')">Forgot PIN?</small>
        </div>

        <div class="mt-2 d-flex justify-content-center px-4">
          <p class="custom-small-text text-muted text-center">
                By continuing, you acknowledge that you have read and understood the
                <span class="text-decoration-underline fw-bold" @click="navigate('/terms-and-conditions')">Privacy Policy</span> and 
                <span class="text-decoration-underline fw-bold" @click="navigate('/terms-and-conditions')">Terms of Use</span>
            </p>
        </div>        

        <div class="custom-mt-8 d-flex justify-content-center">
          <img src="@/assets/img/logo.png" width="150px" />
        </div>

        <div class="mt-4 d-flex justify-content-center">
            <small class="text-muted text-center">Powered by Sipranda Capital Ltd.</small>
        </div>    
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '@/apis/api'
import TopNav from '@/components/TopNav.vue'
import BottomMenu from '@/components/BottomMenu.vue'

export default {
  name: 'LoginPin',
  components: {
    TopNav,
    BottomMenu
  },

  data() {
    return {
      back: "/login/step-one",
      pin: ['', '', '', ''],
      stage: "Auth",
      submitBtn: false
    }
  },

  computed: {
    ...mapGetters(["getPhoneNumber"])
  },

  mounted() {
    if (this.getPhoneNumber == null) {
      this.navigate("/login/step-one")
      this.showError("Phone number not found")
    }
  },

  methods: {
    ...mapMutations([
      "setToken",
      "setResetPin",
      "setSecurityQuestion"
    ]),

    handleInput(index) {
      if (this.pin[index] && index < this.pin.length - 1) {
        this.$refs[`input${index + 1}`].focus();
      }
    },

    handleInput(index) {
      if (this.pin[index] === '') {
        this.focusPreviousInput(index)
      } 
      
      else {
        this.focusNextInput(index)
      }
    },

    focusPreviousInput(index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`].focus();
      }
    },

    focusNextInput(index) {
      if (this.pin[index] && index < this.pin.length - 1) {
        this.$refs[`input${index + 1}`].focus();
      }
    },

    formatPin(e) {
      return String(e).substring(0, 1);
    },

    submit() {
      let that = this

      if (this.pin.some(item => item === '')) {
        this.showError("PIN not complete")
      }
      else {
        this.submitBtn = true

        const pinString = this.pin.join('')
        const data = new URLSearchParams()

        data.append('grant_type', 'password')
        data.append('username', this.getPhoneNumber)
        data.append('password', pinString)

        api.signin(data)
          .then((response) => {            
            if(response != undefined) {
              that.setToken = response.data.access_token

              localStorage.setItem("token", response.data.access_token)

              that.hasLoggedInBefore()
            }
            else {
              that.submitBtn = false
              that.showError("An error occured during login.")
            }                       
          })
          .catch((error) => {
            if(error.response) {
              let errorCode = error.response.status
              that.submitBtn = false    

              if(errorCode == 401) {
                that.showError("The credentials you provided are incorrect. Please try again")
              }
              else {
                that.showError("An error occured during login.")
              }      
            }                              
          })
      }
    },

    hasLoggedInBefore() {
      let that = this

      api.info()
        .then((response) => {
          
          that.submitBtn = false

          let resetPin = response.data.resetPin
          let securityQuestion = response.data.setSecurityQuestions      

          that.setResetPin(resetPin)
          that.setSecurityQuestion(securityQuestion)

          if(securityQuestion) {
            that.navigate("/security-question")
          }
          else {
            if(resetPin) {
              that.navigate("/reset-pin")
            }
            else {
              that.navigate("/home")
            }            
          }          
        })
        .catch((error) => {
          that.submitBtn = false
          that.showError(error)
        })
    }
  }
}
</script>
  